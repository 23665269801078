import React from 'react';
import './Home.css';
import logo from './logo.jpg';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import phone from './phone.jpg'
import mail from './mail.jpg'
import residentialcleaning from './ResidentialCleaning.jpg'
import commercialcleaning from './CommercialCleaning.jpg'
import carpetcleaning from './CarpetCleaning.jpg'

const Home = () => {
  const handleButtonClick = () => {
    window.open('https://forms.monday.com/forms/befc78d6b133cebdb7456067ec63289d?r=euc1');
  };
  return (
    <div className="home-container">
      <div className="top-bar">
        <div className="logo-container">
          <img className="logo" src={logo} alt="Breezy Clean Logo" />
        </div>
        <div className="text-container">
          <h1>Breezy Clean</h1>
          <p>spotless spaces</p>
        </div>
        <div className="mobile-number-container">
        <RouterLink className='mobile' to="mailto:info@breezyclean.co.uk"><img className="phone" src={mail}alt="mail" /><p><b>info@breezyclean.co.uk</b></p></RouterLink>
        <RouterLink className='mobile' to="tel:+44 7412 850008"><img className="phone" src={phone}alt="phone" /><p><b>+44 7412 850008</b></p></RouterLink>
        </div>
      </div>
      <div className='welcome'>
      <div className="hero-section">
        {/* <h2>Welcome to Breezy Clean</h2> */}
        <p>Your home and office deserves the best cleaning services.</p>
        <button onClick={handleButtonClick}>
        Enquire here
        </button>
          </div>
      </div>
      <div className="gaps"></div>

      <div className="services-section">
        <div className="top-service">
        <h2>Our services</h2>
        </div>
        <div className="service-card">
          <h3>Residential Cleaning</h3>
          <p>Our professional cleaning services ensure that your home is not only comfortable but also spotlessly clean, leaving you with a healthier and happier living space.
        </p>
          <img src={residentialcleaning} alt='residential cleaning' />
        </div>
        <div className="service-card">
          <h3>Commercial Cleaning</h3>
          <p>We provide top-notch cleaning services for commercial areas, including hospitals, offices, and schools, ensuring that your business premises are always clean and hygienic.</p>
          <img src={commercialcleaning} alt='Commercial cleaning' />
        </div>
        <div className="service-card">
          <h3>Carpet Cleaning</h3>
          <p>Our expert carpet and rug cleaning services will revitalise your carpets, leaving them looking fresh and vibrant, and making your home or business premises more welcoming and inviting.</p>
          <img src={carpetcleaning} alt='Carpet cleaning' />
        </div>
      </div>
      <div className="gaps"></div>
      <div className="about-section">
      <div className="map-section">
        <h2>Areas we cover</h2>
        <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d318888.9381438894!2d-2.6025082!3d51.3604168!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48722bed7d2c594b%3A0xd5382ca288df25f!2sBreezy%20Cleaning%20%26%20Maintenance!5e0!3m2!1sen!2suk!4v1703629517886!5m2!1sen!2suk"
      width="600"
      height="450"
      style={{ border: '0' }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
      </div>
    <div className="facebook-section">
    <h2>Leave a review!</h2>

    <iframe 
    src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fbreezycleaningandmaintenance%2Fposts%2Fpfbid02eXj4FgNC1w7PxKwS8FttftNsmDweBDgeGG7FDyackCBTwoQwKye7EmaEsfjTJpKhl&show_text=true&width=500" 
    width="500" 
    height="237" 
    style= {{border:'none', 
      overflow:'hidden'}}
    scrolling="no" 
    frameborder="0" 
    allowfullscreen={true}
    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
    ></iframe>
    </div>
      </div>
      <div className="gaps"></div>
      <div id='contact' className="contact-section">
        <h2>Contact Us</h2>
        <p>
      Have questions or want to schedule a cleaning? Reach out to us!<br />
      If the form below is not displaying properly, use{' '}
      <a
        href="https://forms.monday.com/forms/befc78d6b133cebdb7456067ec63289d?r=euc1"
        style={{ color: 'black', textDecoration: 'none', fontWeight: 'bold' }}
        target="_blank"
        rel="noopener noreferrer"
      >
        this link
      </a>
    </p>
        <iframe className="formIframe"
      src="https://forms.monday.com/forms/embed/befc78d6b133cebdb7456067ec63289d?r=euc1"
      width="650"
      height="500"
    ></iframe>
      </div>
      
      <div className="footer">
        <p>&copy; 2023 Breezy Clean. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Home;
